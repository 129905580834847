import { graphql } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  useContext,
  useEffect,
  FunctionComponent,
  useMemo,
  useState
} from 'react';
import { Navbar } from '../components/navbar/navbar';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import { PageProps } from 'gatsby';
import { Container } from '../components/container/container.component';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import '../styles/global.css';
import '../styles/reset.css';
import { seoDataResolver } from '../components/seo-component/seoDataResolver';
import { SeoComponent } from '../components/seo-component/seo-component';
import { ContactPageModel } from '../models/contact-page-model';
import { ContactPersonModel } from '../models/contact-person-model';
import { ContactForm } from '../components/form/contact-form/contact-form';
import styles from '../styles/contact-page.module.scss';
import axios from 'axios';
import { withCookieBar } from '../hoc/withCookieBar';

interface IData {
  kontentItemPage: IPage;
  kontentItemContactFormPage: ISEOComponent & IContactForm;
  allKontentItemContactFormPerson: {
    nodes: [IContactPerson];
  };
  cookieSection: ICookieData;
}

type IndexPageProps = PageProps<IData, IPageContext>;

const Index: React.FunctionComponent<IndexPageProps> = props => {
  const { dispatch, state } = useContext(AppStore);
  const [userLocation, setUserLocation] = useState('pl');
  const [personCountry, setPersonCountry] = useState('');

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });
    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          origin: props.location.origin,
          prevUrl: props.location.state ? props.location.state.prevUrl : '',
          currentUrl: props.location.href || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    const debouncedResize = debounce(handleResize, 300);

    handleResize();
    handleChangePageState();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    props.data.cookieSection.elements.button_cta.value,
    props.data.cookieSection.elements.text.value,
    props.location.href,
    props.location.origin,
    props.location.state,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    state.showActiveDevice,
    state.showPageState.currentUrl
  ]);

  // TODO - not working on https
  const getUserLocation = async (): Promise<void> => {
    return await axios
      .get(
        'http://api.ipstack.com/94.231.229.131?access_key=066b2f70f38e116f58cb27ebf88f326a'
      )
      .then(({ data }) => {
        data.country_code
          ? setUserLocation(data.country_code.toLowerCase())
          : setUserLocation('pl');
      })
      .catch(err => {
        setUserLocation('pl');
      });
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const pageData = useMemo(
    () =>
      props.data.kontentItemContactFormPage.elements
        ? ContactPageModel.create(props.data.kontentItemContactFormPage)
        : null,
    [props.data.kontentItemContactFormPage]
  );

  const contactPersonData = useMemo(() => {
    if (pageData && pageData.form.contactPerson) {
      return pageData.form.contactPerson;
    }

    const person = props.data.allKontentItemContactFormPerson.nodes.filter(
      person =>
        person.elements.country_code.value
          ? person.elements.country_code.value.includes(userLocation)
          : null
    );

    const modeledPerson = person ? ContactPersonModel.create(person[0]) : null;

    modeledPerson?.country && setPersonCountry(modeledPerson.country);

    return modeledPerson?.person;
  }, [
    pageData,
    props.data.allKontentItemContactFormPerson.nodes,
    userLocation
  ]);

  const seoData = useMemo(
    () =>
      props.data.kontentItemContactFormPage.elements
        ? seoDataResolver(props.data.kontentItemContactFormPage.elements)
        : null,
    [props.data.kontentItemContactFormPage.elements]
  );

  return (
    <>
      <SeoComponent
        {...seoData}
        currentUrl={props.location.href}
        lang={props.pageContext.pageLanguage || DEAFUALT_LANGUAGE}
      />
      <Navbar navbarTransparent={false} />
      {pageData && (
        <div className={styles.wrapper}>
          <Container>
            <div className={styles.contentWrapper}>
              <div className={styles.content}>
                <div className={styles.headerWrapper}>
                  <div
                    className={styles.header}
                    dangerouslySetInnerHTML={{ __html: pageData?.pageHeader }}
                  ></div>
                </div>
                <div className={styles.contactForm}>
                  <ContactForm
                    checkboxes={pageData.form.checkboxes}
                    contactPerson={contactPersonData}
                    inputs={pageData.form.inputs}
                    postUrl={pageData.form.postUrl}
                    successMessage={pageData.form.successMessage}
                    contactButtonCta={pageData.form.contactButtonCta}
                  />
                </div>
              </div>
              <div className={styles.contactPersonWrapper}>
                {contactPersonData && (
                  <div className={styles.contactPerson}>
                    <h3>{pageData.contactPersonTitle}</h3>
                    <div className={styles.imageWrapper}>
                      {contactPersonData.photoUrl && (
                        <img src={contactPersonData.photoUrl} alt="person" />
                      )}
                    </div>
                    <div className={styles.personInfo}>
                      <div className={styles.name}>
                        <strong>
                          {contactPersonData.firstName}{' '}
                          {contactPersonData.lastName}
                        </strong>
                      </div>
                      <div>{contactPersonData.position}</div>
                      <div>{personCountry}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default withAppStore(withCookieBar(Index as FunctionComponent<{}>));

export const query = graphql`
  query($pageId: String, $pageLanguage: String) {
    kontentItemContactFormPage(
      system: { id: { eq: $pageId }, language: { eq: $pageLanguage } }
    ) {
      ...FragmentContactFormPage
    }
    allKontentItemContactFormPerson(
      filter: { system: { language: { eq: $pageLanguage } } }
    ) {
      nodes {
        ...FragmentContactPerson
      }
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
  }
`;
